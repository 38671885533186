<template>
  <div class="">
    <el-form-item label="條件類型" prop="conditionType">
      <el-select
        v-model="syncFormData.conditionType"
        :disabled="!syncFormData.enable"
        @change="onConditionChange"
      >
        <el-option
          v-for="option in conditionOptionsConfig"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item
      v-if="syncFormData.conditionType === 'periodAmount'"
      prop="name"
      label-position="left"
    >
      <template slot="label">
        <div class="inline-flex items-center" style="gap: 12px">
          指定期間
          <el-form-item prop="months">
            <el-input
              v-model="syncFormData.months"
              style="width: 50px"
              :disabled="!syncFormData.enable"
            />
          </el-form-item>
          月，消費滿
          <el-form-item prop="amount">
            <el-input
              v-model="syncFormData.amount"
              style="width: 100px"
              :disabled="!syncFormData.enable"
            />
          </el-form-item>
          元，可{{ wording }}至此會員級別
        </div>
      </template>
    </el-form-item>

    <el-form-item
      v-if="syncFormData.conditionType === 'periodCount'"
      prop="name"
      label-position="left"
    >
      <template slot="label">
        <div class="inline-flex items-center inline" style="gap: 12px">
          指定期間
          <el-form-item prop="months">
            <el-input
              v-model="syncFormData.months"
              style="width: 50px"
              :disabled="!syncFormData.enable"
            />
          </el-form-item>
          月，消費滿
          <el-form-item prop="amount">
            <el-input
              v-model="syncFormData.amount"
              style="width: 100px"
              :disabled="!syncFormData.enable"
            />
          </el-form-item>
          次，可{{ wording }}至此會員級別
        </div>
      </template>
    </el-form-item>

    <el-form-item
      v-if="syncFormData.conditionType === 'onceAmount'"
      prop="name"
    >
      <template slot="label">
        <div class="inline-flex items-center" style="gap: 12px">
          單次消費滿
          <el-form-item prop="amount">
            <el-input v-model="syncFormData.amount" style="width: 100px" />
          </el-form-item>
          元，可{{ wording }}至此會員級別
        </div>
      </template>
    </el-form-item>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
import { conditionOptionsConfig } from '@/config/memberLevel'

export default defineComponent({
  name: 'LevelConditionFileds',
  props: {
    formData: Object,
    wording: { type: String, default: '維持' },
  },
  emits: ['update:formData', 'clear'],
  setup (props, { emit }) {
    const syncFormData = useVModel(props, 'formData', emit)

    const onConditionChange = () => {
      syncFormData.value.months = null
      syncFormData.value.amount = null
      emit('clear')
    }

    return { syncFormData, conditionOptionsConfig, onConditionChange }
  },
})
</script>

<style lang="postcss" scoped></style>
