export const levelConfig = {
  1: { label: '1', value: 1 },
  2: { label: '2', value: 2 },
  3: { label: '3', value: 3 },
  4: { label: '4', value: 4 },
}

export const conditionOptionsConfig = {
  periodAmount: { label: '指定期間消費滿額', value: 'periodAmount' },
  onceAmount: { label: '單次消費滿額', value: 'onceAmount' },
  periodCount: { label: '指定期間消費特定次數', value: 'periodCount' },
}

export const levelUpChannelsConfig = {
  appointment: { label: '預約訂單', value: 'appointment', featureKey: 'admin.appointmentOrder.page' },
  eCommerce: { label: '電商訂單', value: 'eCommerce', featureKey: 'admin.ecommerceOrder.page' },
  memberStore: { label: '會員商城訂單', value: 'memberStore', featureKey: 'admin.memberStoreOrder.page' },
  saleRecord: { label: '銷售紀錄訂單', value: 'saleRecord', featureKey: 'admin.salesRecord.page' },
  externalTransaction: { label: '第三方交易訂單', value: 'externalTransaction', featureKey: ['admin.memberExternalTransaction.page', 'admin.shopCustomModule.posPalMemberWallet'] },
}

export const memberLevelFieldsConfig = {
  name: { label: '等級名稱', value: 'name', order: 1 },
  validTimeRange: { label: '有效期限', value: 'validTimeRange', order: 2 },
  levelUpCondition: { label: '升等條件類型', value: 'levelUpCondition' },
  levelUpPeriod: { label: '升等條件類型', value: 'levelUpCondition' },
  levelUpSources: { label: '升等計算適用消費管道', value: 'levelUpSources' },

  levelRemainCondition: { label: '續等條件類型', value: 'levelUpCondition' },
  levelRemainPeriod: { label: '續等條件類型', value: 'levelUpCondition' },
  levelRemainSources: { label: '續等計算適用消費管道', value: 'levelRemainSources' },

  conditionLevelUp: { label: '升等條件內容', value: 'conditionLevelUp' },
  conditionLevelRemain: { label: '續等條件內容', value: 'conditionLevelRemain' },
}

export const updateFieldDataConfig = {
  periodAmount: { label: '指定期間消費滿額', value: 'periodAmount' },
  onceAmount: { label: '單次消費滿額', value: 'onceAmount' },
  periodCount: { label: '指定期間消費特定次數', value: 'periodCount' },
}

export const memberLevelHistoryActionTypeConfig = {
  updated: { label: '編輯', value: 'updated' },
  created: { label: '新建', value: 'created' },
}
