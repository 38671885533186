<template>
  <BaseDialog
    :show="true"
    title="提示"
    @cancel="$emit('cancel')"
    @close="$emit('close')"
    @confirm="$emit('confirm')"
  >
    <div>
      <p>點擊「確認」後，將於隔日 00:00 套用調整後的規則。</p>
      <p>備註：已於此等級當中的會員，再次消費後才會依照新的會員等級條件進行運作。</p>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'MemberLevelEditNoticeModal',
  components: { BaseDialog },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>

</style>
