<template>
  <div v-loading="loading" class="member-level-edit">
    <PageTitle
      title="會員等級設定詳情"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'MemberLevelSetting' })"
    />

    <div v-if="!loading" class="forms-block-wrapper">
      <MemberLevelBasicInfoBlock
        :configData="configData"
        :watchData.sync="watchData"
      />
      <el-alert
        v-show="disabledLevelChange"
        title="提醒："
        type="warning"
        show-icon
        center
        :closable="false"
      >
        <p>目前無開啟適用消費管道相關的模組，因此無法設定升、續等條件。</p>
        <p>如有相關需求請與歐巴小幫手聯繫，以取得協助。</p>
      </el-alert>

      <MemberLevelUpConditionBlock
        v-if="configData.level !== 1"
        :configData="configData"
        :watchData.sync="watchData"
        :disabled="disabledLevelChange"
        :features="features"
      />
      <MemberLevelKeepConditionBlock
        :hidden="configData.level === 1"
        :configData="configData"
        :watchData.sync="watchData"
        :disabled="disabledLevelChange"
        :features="features"
      />
      <PageFixedFooter @cancel="onCancel" @confirm="onValidate" />
    </div>

    <MemberLevelEditNoticeModal
      v-if="showNotice"
      @close="showNotice = false"
      @confirm="onSubmit"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref, onMounted, reactive } from 'vue'
import MemberLevelBasicInfoBlock from './components/MemberLevelBasicInfoBlock.vue'
import MemberLevelUpConditionBlock from './components/MemberLevelUpConditionBlock.vue'
import MemberLevelKeepConditionBlock from './components/MemberLevelKeepConditionBlock.vue'
import MemberLevelEditNoticeModal from './components/MemberLevelEditNoticeModal.vue'
// import ChildB from './components/ChildB.vue'
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { useRoute, useRouter } from 'vue-router/composables'
import FormsContext from './formsContext'
import { UpdateMemberLevel, FindMemberLevelConfig } from '@/api/memberLevel'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { usePermissions } from '@/use/permissions'
import { levelUpChannelsConfig } from '@/config/memberLevel'
import { filter, isArray } from 'lodash'

export default defineComponent({
  name: 'MemberLevelEdit',
  components: {
    PageFixedFooter,
    MemberLevelBasicInfoBlock,
    MemberLevelUpConditionBlock,
    MemberLevelKeepConditionBlock,
    MemberLevelEditNoticeModal,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()
    const { checkAction, checkActionList } = usePermissions()
    const memberLevelId = computed(() => route.params.id)
    const showNotice = ref(false)
    const configData = ref({})
    const loading = ref(true)

    const disabledLevelChange = computed(() => {
      if (memberLevelId.value === 1) return false
      const availables = filter(levelUpChannelsConfig, (channel) => {
        if (isArray(channel.featureKey)) return checkActionList(channel.featureKey, 'intersection')
        return checkAction(channel.featureKey)
      })
      return !availables.length
    })

    const features = reactive({
      useExternalTransaction: computed(() => checkAction('admin.memberExternalTransaction.page')),
      usePospal: computed(() => checkAction('admin.shopCustomModule.posPalMemberWallet')),
    })

    const watchData = reactive({
      validTimeUnit: 'forever',
    })

    const onCancel = () => {
      router.push({
        name: 'MemberLevelSetting',
      })
    }

    const submitData = () => {
      const data = FormsContext.composFormData()
      return {
        shopId: shopId.value,
        ...data,
      }
    }

    const onValidate = async () => {
      const allPass = await FormsContext.validateAll()
      if (!allPass) return
      showNotice.value = true
    }

    const onSubmit = async () => {
      const payload = submitData()
      if (memberLevelId.value) {
        await UpdateMemberLevel(payload)
        window.$message.success('更新成功!')
        showNotice.value = false
        router.push({ name: 'MemberLevelSetting' })
        return
      }
      console.log('TODO create member level')
    }

    const findMemberLevelConfig = async () => {
      await simpleFetch(
        FindMemberLevelConfig,
        {
          shopId: shopId.value,
          level: memberLevelId.value,
        },
        (res) => {
          configData.value = res
        },
      )
    }

    onMounted(async () => {
      if (memberLevelId.value) await findMemberLevelConfig()
      loading.value = false
    })

    return {
      onCancel,
      onSubmit,
      showNotice,
      onValidate,
      configData,
      loading,
      watchData,
      disabledLevelChange,
      features,
    }
  },
})
</script>

<style scoped lang="postcss">
.forms-block-wrapper {
  @apply flex flex-col gap-[20px];
}

.level-change-disabled-hint {
  @apply text-danger m-auto;
}
</style>
