<template>
  <div class="">
    <el-form-item :label="label" prop="channels">
      <el-checkbox-group v-model="syncFormData.channels" :disabled="!syncFormData.enable">
        <div class="flex flex-col">
          <el-checkbox
            v-for="option in levelUpChannelsConfig"
            v-show="checkFeatureToShow(option.featureKey)"
            :key="option.value"
            :label="option.value"
          >
            {{ option.label }}
          </el-checkbox>
        </div>
      </el-checkbox-group>
    </el-form-item>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
import { levelUpChannelsConfig } from '@/config/memberLevel'
import { usePermissions } from '@/use/permissions'
import { isArray } from 'lodash'

export default defineComponent({
  name: 'LevelChannelFields',
  props: {
    formData: Object,
    label: String,
  },
  emits: ['update:formData'],
  setup (props, { emit }) {
    const { checkAction, checkActionList } = usePermissions()
    const syncFormData = useVModel(props, 'formData', emit)

    const checkFeatureToShow = (feature) => {
      console.log('isArray', isArray(feature))
      if (isArray(feature)) return checkActionList(feature, 'intersection')
      return checkAction(feature)
    }

    return { syncFormData, levelUpChannelsConfig, checkAction, checkFeatureToShow }
  },
})
</script>

<style lang="postcss" scoped>

</style>
