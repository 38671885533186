<template>
  <div
    class="member-level-up-condition-block side-highlight-container"
    :class="{ disabled: !formData.enable }"
  >
    <div style="margin-bottom: 20px">
      <div class="flex justify-between items-center">
        <p class="card-title" style="margin-bottom: 0px">升等條件</p>
        <el-switch
          v-if="!disabled"
          v-model="formData.enable"
          inactive-text="關閉"
          active-text="開啟"
        />
      </div>
      <p class="text-sm">
        系統將於凌晨 6:00 觸發計算，經計算在指定期間內滿足條件時，系統將自動將該會員升等至此會員等級
      </p>
    </div>
    <el-form
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="top"
    >
      <LevelCondition
        :formData.sync="formData"
        wording="升等"
        @clear="onClearValidation"
      />
      <LevelChannelFields
        label="升等計算適用消費管道"
        :formData.sync="formData"
        @clear="onClearValidation"
      />

      <div v-if="features.useExternalTransaction || features.usePospal" class="hint flex items-center" style="gap: 8px">
        <p>註：第三方交易訂單消費金額只認列特定支付方式，其他支付方式不認列</p>
        <router-link :to="{name: 'PosPaymentRecognize'}" target="_blank">
          <p class="underline">看詳細資訊</p>
        </router-link>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  nextTick,
  watch,
  computed,
  ref,
  reactive,
  set,
} from 'vue'
import LevelCondition from './LevelConditionFields.vue'
import LevelChannelFields from './LevelChannelFields.vue'
import { isDigitRules, minRules, noEmptyRules } from '@/validation'
import FormsContext from '../formsContext'
import { get, toNumber, pick } from 'lodash'
import formUtils from '@/utils/form'
import { useRoute } from 'vue-router/composables'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'MemberLevelUpConditionBlock',
  components: { LevelCondition, LevelChannelFields },
  props: {
    configData: { type: Object, default: () => ({}) },
    watchData: { type: Object, default: () => ({}) },
    disabled: Boolean,
    features: { type: Object, default: () => ({}) },
  },
  emits: [],
  setup (props, { emit }) {
    const route = useRoute()
    const { checkAction } = usePermissions()
    const formRef = ref(null)
    const memberLevelId = computed(() => route.params.id)

    const formData = reactive({
      enable: false,
      channels: [],
      conditionType: 'full',
      months: null,
      amount: null,
    })

    const formRules = computed(() => {
      if (!formData.enable) return {}
      const rules = {
        channels: [noEmptyRules()],
        conditionType: [noEmptyRules()],
        months: [noEmptyRules(), isDigitRules(), minRules(1)],
        amount: [noEmptyRules(), isDigitRules(), minRules(1)],
      }

      // handle rules here

      return rules
    })

    watch(formRules, () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 0)
    })

    const compactData = computed(() => {
      const data = { ...formData }
      // data handle here
      if (!get(data, 'enable')) {
        return {
          levelUpCondition: 'none',
          levelUpPeriod: null,
          levelUpThreshold: null,
          levelUpSources: [],
        }
      }

      return {
        levelUpCondition: get(data, 'conditionType'),
        levelUpPeriod: toNumber(get(data, 'months')) || null,
        levelUpThreshold: toNumber(get(data, 'amount')) || null,
        levelUpSources: get(data, 'channels'),
      }
    })

    const onClearValidation = () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 0)
    }

    watch(compactData, () => {
      FormsContext.setFormData('levelUpCondition', compactData.value)
    })

    const syncData = () => {
      const keysMap = {
        levelUpCondition: 'conditionType',
        levelUpSources: 'channels',
        levelUpPeriod: 'months',
        levelUpThreshold: 'amount',
      }
      const data = pick(props.configData, Object.keys(keysMap))
      for (const key in props.configData) {
        set(formData, get(keysMap, key), get(data, key))
      }
      const conditionType = get(data, 'levelUpCondition')
      if (conditionType !== 'none') {
        formData.enable = true
      }
      if (conditionType === 'none') {
        formData.conditionType = null
      }
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('levelUpCondition', formRef.value)
      if (memberLevelId.value) syncData()
      FormsContext.setFormData('levelUpCondition', compactData.value)
    })

    return {
      formRef,
      formData,
      formRules,
      onClearValidation,
      compactData,
    }
  },
})
</script>

<style scoped lang="postcss">
.hint {
  @apply text-sub text-gray-80;
}
</style>
